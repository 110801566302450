import "./style.scss";

import { useEffect, useState } from "react";

import AttachField from "../../FormControl/InputField/_attach";
import Email from "../../FormControl/InputField/_email";
import Heading from "../../Heading";
import Notice from "../../Notice";
import PrivacyCheckbox from "../../FormControl/CheckboxNew/_privacy";
import PropTypes from "prop-types";
import SubmitButton from "../../Buttons/Submit";
import TextArea from "../../FormControl/InputField/_textArea";
import Title from "../../Title";
import TranslationWord from "../../Translate_component";
import { createClassName } from "../../../helpers";
import { errorNoticeMessage } from "../../Notice/constantMessage";
import { sendRequest } from "../../Api_request";
import { supportDataMapping } from "../../Api_request/dataMapping";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next/dist/es/useTranslation";

const cn = createClassName("general-form");

const heading = props => (
  <Heading
    level={props.bigHeading ? 1 : 2}
    fontLevel={2}
    weight="medium"
    align={props.alignTitle}
    className={props.classNameTitle}
  >
    {props.heading}
  </Heading>
);

const title = props => (
  <Title
    level={props.levelSubtitle}
    align={props.alignSubtitle}
    className={props.classNameSubtitle}
    color={props.color}
  >
    {props.title}
  </Title>
);

const errorMessage = props => (
  <Notice
    withButton={false}
    errorFooterText={errorNoticeMessage}
    className={cn("message", { fullWidth: props.fullWidthNotice })}
  />
);

const GeneralForm = props => {
  const { t } = useTranslation("forms");
  const [isSend, getSending] = useState(false);
  const [isServerResponse, setServerResponse] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [mailAddress, setMailAddress] = useState("");

  const { register, handleSubmit, errors, watch, reset } = useForm({
    defaultValues: {
      PrivacyPolicy: false,
    },
  });

  const onSubmit = data => {
    setLoading(true);
    setMailAddress(watch("email"));
    sendRequest(
      "contact-us",
      supportDataMapping(data),
      getSending,
      setServerResponse,
      setLoading
    );
  };

  useEffect(() => {
    isSend && reset();
  }, [isSend]);

  const successMessage = (fullWidth = false) => (
    <Notice
      successSend
      withButton={false}
      mailerAddress={mailAddress}
      successHeaderTitle={t("defaultText.received")}
      successFooterText={t("defaultText.successFooterText")}
      className={cn("message", {
        fullWidth: fullWidth ? "success" : props.fullWidthNotice,
      })}
    />
  );

  const fullWidthSuccessNotice = (
    <>
      <Title
        color="primary-main"
        level={3}
        className={cn("message", { fullWidth: "title" })}
      >
        {t("common:thanks")}
      </Title>
      {successMessage(isSend)}
    </>
  );

  return (
    <section>
      {isSend && props.fullWidthNotice ? (
        fullWidthSuccessNotice
      ) : (
          <>
            {props.heading && heading(props)}
            {props.title && title(props)}
            {/* {successMessage()} */}
            <form
              encType="multipart/form-data"
              onSubmit={handleSubmit(onSubmit)}
              className={props.classNameForm}
            >
              <TextArea
                isLabel
                labelPlacement="top"
                className={cn("input-field")}
                fieldClassName={`${cn("input-field", { textarea: true })} ${props.classNameTextarea
                  }`}
                labelClassName={cn("input-field", { label: true })}
                errors={errors}
                register={register({ required: true })}
                isErrorState={errors?.message && true}
                placeholder={props.textareaPlaceholder}
              />
              {props.withAttach && (
                <AttachField register={register()} className={cn("attach")} />
              )}
              <Email
                isLabel
                labelPlacement="top"
                className={cn("input-field", { email: true })}
                labelClassName={cn("input-field", { label: true })}
                errors={errors}
                register={register}
                isErrorState={errors?.email && true}
                name="email"
              />
              <PrivacyCheckbox
                name="PrivacyPolicy"
                className={cn("checkbox")}
                tooltipContent={t("tooltip.default")}
                register={register({ required: true })}
                isErrorState={errors?.PrivacyPolicy && true}
              />
              {!isServerResponse && errorMessage(props)}
              {isSend && !props.fullWidthNotice ? (
                successMessage()
              ) : (
                  <SubmitButton
                    className={props.classNameSubmit}
                    isLoading={isLoading}
                    buttonTextKey={
                      <TranslationWord text={`${isServerResponse ? "send" : "try-again"}`} />
                    }
                  />
                )}
            </form>
          </>
        )}
    </section>
  );
};

GeneralForm.defaultProps = {
  tooltipContent: "",
  textareaPlaceholder: "",
  successFooterText: "",
  fullWidthNotice: false,
  levelSubtitle: 5,
  withAttach: true,
  classNameTextarea: "",
};

GeneralForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  classNameForm: PropTypes.string,
  classNameTitle: PropTypes.string,
  classNameSubtitle: PropTypes.string,
  classNameSubmit: PropTypes.string,
  alignTitle: PropTypes.string,
  alignSubtitle: PropTypes.string,
  textareaPlaceholder: PropTypes.string,
  checkBoxText: PropTypes.string || PropTypes.object,
  withAttach: PropTypes.bool,
  classNameTextarea: PropTypes.string,
};

export default GeneralForm;
