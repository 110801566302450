import "./style.scss";

import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import TranslationWord from "../../../Translate_component";
import { createClassName } from "../../../../helpers";

// import { useTranslation } from "react-i18next/dist/es/useTranslation";

const cn = createClassName("attach-field");
const attachFileNames = (text) => <TranslationWord text={text} isForm />;

const AttachField = (props) => {
  // const { t } = useTranslation("forms");
  // const [attachFileName, setFile] = useState("");
  const [attachFileName, setFile] = useState(attachFileNames("attach"));

  const setDefaultButtonText = (target) => {
    setFile(attachFileNames("attach"));
    // setFile(attachFileName("placeholder.attach"));
    (target.style.color = "#d9d9d9");
  };

  useEffect(() => {
    const file = document.getElementById("attach-field");
    const fileName = document.getElementById("attach-file-name");
    // const deleteFileName = document.getElementById("delete-attach-file-button");

    const changeFileName = () => {
      if (file.files.length) {
        setFile(file.files[0].name);
        (fileName.style.color = "#333333");
      } else {
        setDefaultButtonText(fileName);
      }
    };

    file.addEventListener("change", () => {
      changeFileName();
    });

    // !!deleteFileName && deleteFileName.addEventListener("click", (e) => {
    //   e.preventDefault();
    //   setDefaultButtonText(fileName);
    //   console.log(e)
    // });

    return () => file.removeEventListener("change", (e) => {
      changeFileName();
    });
  }, [attachFileName]);

  return (
    <label className={`${cn("wrap")} ${props.className}`}>
      <input
        id="attach-field"
        type="file"
        name="file"
        ref={props.register}
        className={cn("input", { hidden: true })}
      />
      <div className={cn("input", { field: true })}>
        <div className={cn("input", { wrap: true })}>
          <span id="attach-file-name" className={cn("file-name")}>
            {attachFileName}
            {/* {attachFileNames("attach")} */}
          </span>
        </div>
        {/* {attachFileName === t("placeholder.attach") ? ( */}
        <div id="attach-file-button-text" className={cn("btn", { insert: true })}>
          {/* {t("common:attach")} */}
          <TranslationWord text="attach" />
        </div>
        {/* ) : (
            <div id="delete-attach-file-button" className={cn("btn", { delete: true })}>
              {t("common:delete")}
            </div>
          )
        } */}
      </div>
    </label>
  );
};

AttachField.defaultProps = {
  className: "",
  register: () => { },
};

AttachField.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
};

export default AttachField;
