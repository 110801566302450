import PropTypes from "prop-types";
import InputFieldWithValidation from "../_mail";
import TranslationWord from "../../../Translate_component";

// import { useTranslation } from "react-i18next/dist/es/useTranslation";

// const placeholderText = <TranslationWord text="textarea" isForm />;

const TextArea = (props) =>
  // const { t } = useTranslation("common");

   (
     <InputFieldWithValidation
       tag="textarea"
       type="text"
       name={props.name}
       errors={props.errors}
       labelPlacement={props.isLabel ? props.labelPlacement : undefined}
       labelClassName={props.labelClassName}
       label={props.isLabel ? <TranslationWord text="message" /> : undefined}
      // label={props.isLabel ? t("message") : undefined}
       placeholder={props.placeholder}
      // placeholder={
      //   props.placeholder ? props.placeholder : t("forms:placeholder.textarea")
      // }
       register={props.register}
       isIncorrectField={false}
       fieldClassName={props.fieldClassName}
       className={props.className}
       isErrorState={props.isErrorState}
     />
  );
TextArea.defaultProps = {
  isLabel: true,
  placeholder: "",
  name: "message",
  className: "",
  fieldClassName: "",
  labelClassName: "",
  isErrorState: "",
  register: () => { },
};
TextArea.propTypes = {
  errors: PropTypes.object.isRequired,
  isLabel: PropTypes.bool,
  register: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  fieldClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  isErrorState: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
};

export default TextArea;
