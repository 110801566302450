import "./style.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import { ErrorFooterTextDefault } from "./constantMessage.js";
import SubmitButton from "../Buttons/Submit";
import Title from "../Title";
import { createClassName } from "../../helpers";
import TranslationWord from "../Translate_component";

const cn = createClassName("notice");

const errorFooterTextDefault = (
  <ErrorFooterTextDefault className={cn("email")} href="/" color="primary" />
);

const mailerAddress = (emailAddress) => (
  <>
    <span className={cn({ "word-break": true })}>
      <br />
    </span>
    <span className={cn("email")}>{emailAddress}</span>
  </>
);

const Notice = (props) => {
  const { t } = useTranslation("forms");
  const className = props.successSend ? cn("success") : cn("warning");

  return (
    <>
      <div className={`${cn("wrap")} ${className} ${props.className}`}>
        <Title level={5} weight="medium" {...props}>
          {props.successSend ? (
            <>
              {props.successHeaderTitle
                ? props.successHeaderTitle
                : t("defaultText.successHeaderTitle")}
              {" "}
              {props.mailerAddress && (
                <>
                  {mailerAddress(props.mailerAddress)}
                  .
                </>
              )}
            </>
          ) : (
            <>
              {props.errorHeaderTitle
                  ? props.errorHeaderTitle
                  : t("forms:defaultText.errorHeaderTitle")}
              {mailerAddress(props.mailerAddress)}
            </>
            )}
        </Title>
        <p className={cn("text")}>
          {props.successSend ? props.successFooterText : props.errorFooterText}
        </p>
      </div>
      {props.withButton && !props.successSend && (
        <SubmitButton
          className={`${cn("button", { errorSubmit: true })} ${
            props.errorSubmitButtonClassName
          }`}
          handler={props.handler}
          buttonTextKey={
            <TranslationWord text="try-again" />
          }
          isLoading={props.isLoading}
        />
      )}
    </>
  );
};

Notice.defaultProps = {
  className: "",
  errorSubmitButtonClassName: "",
  successSend: false,
  isLoading: false,
  successHeaderTitle: "",
  errorHeaderTitle: "",
  successFooterText: "",
  errorFooterText: errorFooterTextDefault,
  withButton: true,
  handler: () => { },
};

Notice.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  errorSubmitButtonClassName: PropTypes.string,
  handler: PropTypes.func,
  successSend: PropTypes.bool,
  withButton: PropTypes.bool,
  successHeaderTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  successFooterText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorHeaderTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorFooterText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Notice;
